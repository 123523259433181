import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import 'firebase/auth'
import { FIREBASE_CONFIG } from '../config'
import { notify } from './notifications'

if (!getApps().length) {
  initializeApp(FIREBASE_CONFIG)
}

export const firebaseAuth = getAuth()

export function loginErrorNotification(code) {
  let errorMessage = ''
  if (code === 'auth/invalid-email') {
    errorMessage = 'Email address is not valid.'
  } else if (code === 'auth/user-disabled') {
    errorMessage = 'User corresponding to the given email has been disabled.'
  } else if (code === 'auth/user-not-found') {
    errorMessage = 'No user corresponding to the given email.'
  } else if (code === 'auth/wrong-password') {
    errorMessage =
      'Password is invalid for the given email, or the account corresponding to the email does not have a password set.'
  } else {
    errorMessage = `Login Error! Code: ${code}`
  }
  notify({
    message: errorMessage,
    type: 'error',
    time: 5000,
  })
}
