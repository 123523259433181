import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import {
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth'
import { firebaseAuth, loginErrorNotification } from '../../utils/firebaseClient'
import { notify } from '../../utils/notifications'
import Cookie from 'js-cookie'
import 'react-tabs/style/react-tabs.css'
import {
  userNameState,
  userEmailState,
  userAccessTokenState,
  userProfileImageState,
  userReferralCodeState,
  KEY_USER_ACCESSTOKEN_COOKIE,
  KEY_USER_EMAIL_COOKIE,
  KEY_USER_NAME_COOKIE,
  KEY_USER_PROFILE_IMAGE_COOKIE,
  KEY_USER_REFERRAL_CODE_COOKIE,
} from '../../utils/states'
import { getHourDate } from '../../utils/utils'
import { fetchGetMe } from '../../utils/client/auth/user-api'
import Cookies from 'js-cookie'

import '@fortawesome/fontawesome-free/css/all.min.css'

const Login = ({ referral }) => {
  const [loginLoading, setLoginLoading] = useState(false)
  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')

  const [userName, setUserNameState] = useRecoilState(userNameState)
  const [userEmail, setUserEmailState] = useRecoilState(userEmailState)
  const [userProfileImage, setUserProfileImageState] = useRecoilState(userProfileImageState)
  const [userReferralCode, setUserReferralCodeState] = useRecoilState(userReferralCodeState)
  const [userAccessToken, setUserAccessTokenState] = useRecoilState(userAccessTokenState)

  const router = useRouter()

  useEffect(() => {
    if (userName && userEmail && userProfileImage && userReferralCode && userAccessToken) {
      fetchGetMe(userAccessToken)
        .then((r) => {
          if (r.status === 200) {
            notify({
              type: 'info',
              message: 'You are already login.',
            })
            router.push(referral || '/')
            // router.replace(referral || '/')
          } else {
            resetLogin()
          }
        })
        .catch((e) => {
          resetLogin()
        })
    } else {
      resetLogin()
    }
  }, [])

  function resetLogin() {
    // init current login
    setUserNameState(undefined)
    setUserEmailState(undefined)
    setUserAccessTokenState(undefined)
    setUserProfileImageState(undefined)
    setUserReferralCodeState(undefined)

    Cookies.remove(KEY_USER_ACCESSTOKEN_COOKIE)
    Cookies.remove(KEY_USER_EMAIL_COOKIE)
    Cookies.remove(KEY_USER_NAME_COOKIE)
    Cookies.remove(KEY_USER_PROFILE_IMAGE_COOKIE)
    Cookies.remove(KEY_USER_REFERRAL_CODE_COOKIE)
  }

  async function requestAppleLogin() {
    try {
      setLoginLoading(true)
      const provider = new OAuthProvider('apple.com')
      const result = await signInWithPopup(firebaseAuth, provider)
      const token = await result.user.getIdToken()
      const response = await fetchGetMe(token)

      if (response.status === 200 && response.data) {
        notify({
          message: `Welcome to @${response.data.userName}!`,
          type: 'success',
        })
        setUserNameState(response.data.userName)
        setUserProfileImageState(response.data.profileImage)
        setUserReferralCodeState(response.data.referralCode)
        setUserEmailState(response.data.email)
        setUserAccessTokenState(token)

        Cookie.set(KEY_USER_NAME_COOKIE, response.data.userName, { expires: getHourDate() })
        Cookie.set(KEY_USER_PROFILE_IMAGE_COOKIE, response.data.profileImage, { expires: getHourDate() })
        Cookie.set(KEY_USER_EMAIL_COOKIE, response.data.email, { expires: getHourDate() })
        Cookie.set(KEY_USER_ACCESSTOKEN_COOKIE, token, { expires: getHourDate() })
        Cookie.set(KEY_USER_REFERRAL_CODE_COOKIE, response.data.referralCode, { expires: getHourDate() })
        router.push(referral || '/')
      } else {
        resetLogin()
        notify({
          message: response.message || 'Error',
          type: 'error',
        })
      }
    } catch (error) {
      resetLogin()
      notify({
        message: error.message,
        type: 'error',
      })
    } finally {
      setLoginLoading(false)
    }
  }

  async function requestGoogleLogin() {
    try {
      setLoginLoading(true)
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(firebaseAuth, provider)
      console.log(result)
      const token = await result.user.getIdToken()
      const response = await fetchGetMe(token)

      if (response.status === 200 && response.data) {
        notify({
          message: `Welcome to @${response.data.userName}!`,
          type: 'success',
        })
        setUserNameState(response.data.userName)
        setUserProfileImageState(response.data.profileImage)
        setUserReferralCodeState(response.data.referralCode)
        setUserEmailState(response.data.email)
        setUserAccessTokenState(token)

        Cookie.set(KEY_USER_NAME_COOKIE, response.data.userName, { expires: getHourDate() })
        Cookie.set(KEY_USER_PROFILE_IMAGE_COOKIE, response.data.profileImage, { expires: getHourDate() })
        Cookie.set(KEY_USER_EMAIL_COOKIE, response.data.email, { expires: getHourDate() })
        Cookie.set(KEY_USER_ACCESSTOKEN_COOKIE, token, { expires: getHourDate() })
        Cookie.set(KEY_USER_REFERRAL_CODE_COOKIE, response.data.referralCode, { expires: getHourDate() })
        router.push(referral || '/')
      } else {
        resetLogin()
        notify({
          message: response.message || 'Error',
          type: 'error',
        })
      }
    } catch (error) {
      resetLogin()
      notify({
        message: error.message,
        type: 'error',
      })
    } finally {
      setLoginLoading(false)
    }
  }

  async function requestTwitterLogin() {
    try {
      setLoginLoading(true)
      const provider = new TwitterAuthProvider()
      const result = await signInWithPopup(firebaseAuth, provider)
      const token = await result.user.getIdToken()
      const response = await fetchGetMe(token)

      if (response.status === 200 && response.data) {
        notify({
          message: `Welcome to @${response.data.userName}!`,
          type: 'success',
        })
        setUserNameState(response.data.userName)
        setUserProfileImageState(response.data.profileImage)
        setUserReferralCodeState(response.data.referralCode)
        setUserEmailState(response.data.email)
        setUserAccessTokenState(token)

        Cookie.set(KEY_USER_NAME_COOKIE, response.data.userName, { expires: getHourDate() })
        Cookie.set(KEY_USER_PROFILE_IMAGE_COOKIE, response.data.profileImage, { expires: getHourDate() })
        Cookie.set(KEY_USER_EMAIL_COOKIE, response.data.email, { expires: getHourDate() })
        Cookie.set(KEY_USER_ACCESSTOKEN_COOKIE, token, { expires: getHourDate() })
        Cookie.set(KEY_USER_REFERRAL_CODE_COOKIE, response.data.referralCode, { expires: getHourDate() })
        router.push(referral || '/')
      } else {
        resetLogin()
        notify({
          message: response.message || 'Error',
          type: 'error',
        })
      }
    } catch (error) {
      resetLogin()
      notify({
        message: error.message,
        type: 'error',
      })
    } finally {
      setLoginLoading(false)
    }
  }

  async function requestLogin() {
    if (!inputEmail || inputEmail.trim() === '') {
      notify({
        message: 'Input your email',
      })
      return
    }

    if (!inputPassword || inputPassword.trim() === '') {
      notify({
        message: 'Input your password',
      })
      return
    }

    setLoginLoading(true)
    signInWithEmailAndPassword(firebaseAuth, inputEmail, inputPassword)
      .then((r) => {
        r.user.getIdToken().then((token) => {
          fetchGetMe(token)
            .then((response) => {
              if (response.status === 200 && response.data) {
                notify({
                  message: `Welcome to @${response.data.userName}!`,
                  type: 'success',
                })
                setUserNameState(response.data.userName)
                setUserProfileImageState(response.data.profileImage)
                setUserReferralCodeState(response.data.referralCode)
                setUserEmailState(response.data.email)
                setUserAccessTokenState(token)

                Cookie.set(KEY_USER_NAME_COOKIE, response.data.userName, { expires: getHourDate() })
                Cookie.set(KEY_USER_PROFILE_IMAGE_COOKIE, response.data.profileImage, { expires: getHourDate() })
                Cookie.set(KEY_USER_EMAIL_COOKIE, response.data.email, { expires: getHourDate() })
                Cookie.set(KEY_USER_ACCESSTOKEN_COOKIE, token, { expires: getHourDate() })
                Cookie.set(KEY_USER_REFERRAL_CODE_COOKIE, response.data.referralCode, { expires: getHourDate() })
                router.push(referral || '/')
              } else {
                resetLogin()
                setLoginLoading(false)
                notify({
                  message: response.errorMessage || 'Error',
                  type: 'error',
                })
              }
            })
            .catch((e) => {
              resetLogin()
              setLoginLoading(false)
              notify({
                message: e.message || 'Error',
                type: 'error',
              })
            })
        })
      })
      .catch((e) => {
        const errorCode = e.code
        resetLogin()
        setLoginLoading(false)
        loginErrorNotification(errorCode)
      })
  }

  return (
    <div>
      <section className="relative h-screen">
        <div className="lg:flex lg:h-full max-w-[1320px] mx-auto justify-center">
          <div className="hidden text-center lg:basis-1/2 items-center justify-center lg:flex">
            <img src="/images/app_screen.png" alt="login" className="max-h-[600px]" />
          </div>

          <div className="w-full relative flex items-center justify-center lg:basis-1/2">
            <div className="mt-14 w-full lg:max-w-[25.625rem] text-center lg:mt-0">
              <Link legacyBehavior href="/">
                <a className="relative inline-block pb-4">
                  <img
                    src="/images/walkmining_explorer_logo_w.svg"
                    className="hidden max-h-12 dark:block"
                    alt="WalkMkning"
                  />
                  <img
                    src="/images/walkmining_explorer_logo_b.svg"
                    className="inline-block max-h-12 dark:hidden"
                    alt="WalkMkning"
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">Welcome to the WalkMining ecosystem.</p>
              <div className="container">
                <form className="mx-auto max-w-[48.125rem]">
                  <div className="mb-6 text-left">
                    <label htmlFor="item-email" className="font-display text-jacarta-700 mb-2 block dark:text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      id="item-email"
                      value={inputEmail}
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                      placeholder="Enter your email"
                      required
                      onChange={(e) => {
                        setInputEmail(e.target.value)
                      }}
                    />
                  </div>
                  <div className="mb-6 text-left">
                    <label htmlFor="item-password" className="font-display text-jacarta-700 mb-2 block dark:text-white">
                      Password
                    </label>
                    <input
                      type="password"
                      id="item-password"
                      value={inputPassword}
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                      placeholder="Enter your password"
                      required
                      onChange={(e) => {
                        setInputPassword(e.target.value)
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loginLoading}
                    className="w-[300px] mb-10 bg-email text-[#050C29] hover:text-[#050C29] rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                    onClick={requestLogin}
                  >
                    {loginLoading ? `Loading...` : `Sign in`}
                  </button>
                </form>
                <div className="w-full">
                  {/* <div className="mt-2">
                    <button
                      disabled={loginLoading}
                      className="w-[300px] bg-twitter hover:bg-twitter-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                      onClick={requestTwitterLogin}
                    >
                      <i className="fab fa-x mr-2"></i>
                      {loginLoading ? `Loading...` : `Login with X`}
                    </button>
                  </div> */}
                  <div className="mt-2 w-full">
                    <button
                      disabled={loginLoading}
                      className="w-[300px] bg-apple hover:bg-apple-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                      onClick={requestAppleLogin}
                    >
                      <i className="fab fa-apple mr-2"></i>
                      {loginLoading ? `Loading...` : `Login with Apple`}
                    </button>
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      disabled={loginLoading}
                      className="w-[300px] bg-google hover:bg-google-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                      onClick={requestGoogleLogin}
                    >
                      <i className="fab fa-google mr-2"></i>
                      {loginLoading ? `Loading...` : `Login with Google`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login
