import React, { useContext, useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import UserContext from '../../components/UserContext'
import Login from '../../components/user/login'

export default function LoginPage(props) {
  const { scrollRef } = useContext(UserContext)
  const { referral } = props

  useEffect(() => {
    window.scrollTo(0, 0)
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY
    }
    window.addEventListener('scroll', handleScrollPos)
    return () => {
      window.removeEventListener('scroll', handleScrollPos)
    }
  })

  return (
    <>
      <Login referral={referral} />
    </>
  )
}

export async function getServerSideProps(ctx) {
  const isSSR = ctx.req !== null && ctx.req !== undefined
  const { ref } = ctx.query
  return { props: { referral: ref || null, isSSR, ...(await serverSideTranslations(ctx.locale, ['common', 'index'])) } }
}
