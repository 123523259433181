import { authHttp, responseOk, responseError } from '../axios-client'
import _ from 'lodash'

export async function fetchGetMe(accessToken, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/me`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetBalance(accessToken, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/wallet`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetMyProfile(accessToken, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/profile`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetMyMiningHistory(accessToken, page = 0, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/mining-history?page=${page}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetMyWalletHistory(accessToken, page = 0, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/wallet-history?page=${page}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetMyGameParticipationHistory(accessToken, page = 0, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/game-participation-history?page=${page}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetMyGameWinningHistory(accessToken, page = 0, isSSR = false) {
  return await authHttp(isSSR, { accessToken })
    .get(`explorer/auth/users/game-winning-history?page=${page}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}
